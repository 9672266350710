import React from "react";
import TextList from "../../common/TextList";
import { StaticImage } from "gatsby-plugin-image";

export default function ThreadForum() {
  return (
    <div className="thread-forum">
      <h2>fórum de segmento</h2>
      <TextList>
        <div className="thread-forum__image">
          <StaticImage
            src="../../images/solução/forum.svg"
            alt="modulo manual"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
        <div className="thread-forum__text">
          <p>
            O Fórum de Segmento integra empresas que atuam no mesmo segmento de
            mercado. Através do fórum é possível trocar experiências e tirar
            dúvidas técnicas e operacionais.
          </p>
          <ul>
            <li>
              <p>Discussões técnicas com pares de forma on-line;</p>
            </li>
            <li>
              <p>Maior agilidade no esclarecimento de práticas;</p>
            </li>
            <li>
              <p>Disseminação do conhecimento contábil;</p>
            </li>
            <li>
              <p>Economia através da redução de consultas técnicas</p>
            </li>
          </ul>
        </div>
      </TextList>
    </div>
  );
}
