import React from "react";
import SectionContainer from "../../common/SectionContainer";
import { StaticImage } from "gatsby-plugin-image";

export default function Ifrs16() {
  return (
    <div className="checklist">
      <SectionContainer backgroundColor="#e2dfdd">
        <div className="checklist__content">
          <div className="checklist__content__text">
            <h2 className="changeMargin">IFRS 16</h2>
            <p>
              Controle dos contratos de arredondamentos através de um sistema
              fácil e objetivo, gerando todas as contabilizações mensais de
              forma automática e com consulta de relatórios gerenciais e
              contábeis.
            </p>
          </div>
          <StaticImage
            src="../../images/solução/ifrs16.png"
            alt="checklist fechamento"
            placeholder="tracedSVG"
          />
        </div>
      </SectionContainer>
    </div>
  );
}
