import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionContainer from "../../common/SectionContainer";

export default function ConhecimentoContabil() {
  return (
    <div className="checklist">
      <SectionContainer backgroundColor="#e2dfdd">
        <h2>check-list de recebimento</h2>
        <div className="checklist__content">
          <div className="checklist__content__text">
            <p>
              É uma ferramenta imprescindível para a gestão das rotinas
              contábeis. O gestor poderá acompanhar toda a rotina de fechamento
              da contabilidade, além do recebimento de lembretes de prazos.
            </p>
            <p>
              A ferramenta permite anexar comprovantes ou documentos aos
              processos.
            </p>
          </div>
          <StaticImage
            src="../../images/solução/checklist_fechamento.png"
            alt="checklist fechamento"
            placeholder="tracedSVG"
          />
        </div>
      </SectionContainer>
    </div>
  );
}
