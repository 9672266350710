import React from "react";
import Button from "../../common/Button";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SectionContainer from "../../common/SectionContainer";

export default function AccountsPlans() {
  return (
    <div className="accounts-plans">
      <SectionContainer>
        <StaticImage
          src="../../images/inicial/sobre.svg"
          alt=""
          placeholder="tracedSVG"
          className=""
        />
        <div className="section-container__content accounts">
          <h2>PLANO DE CONTAS</h2>
          <p className="accounts__text">
            O usuário consegue, de forma simples, saber qual a natureza, o
            responsável e o tipo de uma determinada conta contábil. Além disso,
            é possível relacionar uma conta contábil com um tópico do manual de
            contabilidade, desta forma, caso o usuário tenha alguma dúvida em
            relação a como aplicar determinada norma, em apenas um clique é
            possível que se tenha orientação.
          </p>
          <Button hidden={true}>
            <Link to="/">Saiba Mais</Link>
          </Button>
        </div>
      </SectionContainer>
    </div>
  );
}
