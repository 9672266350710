import React from "react";
import TextList from "../../common/TextList";
import { StaticImage } from "gatsby-plugin-image";

export default function ManualModule() {
  return (
    <div className="manual-module">
      <TextList>
        <div className="manual-module__text">
          <h2>módulo manual contábil e tributário</h2>
          <p>
            Os usuários internos terão acesso a todas as normas e suas
            aplicações no contexto da empresa, através de um sistema
            simplificado de busca.
          </p>
          <ul>
            <li>
              <p>Maior agilidade e assertividade na pesquisa de normas;</p>
            </li>
            <li>
              <p>
                Descrição de todos os processos contábeis e fiscais aplicáveis
                nas atividades de fechamento;
              </p>
            </li>
            <li>
              <p>Disseminação do conhecimento contábil aos usuários;</p>
            </li>
            <li>
              <p>Direcionamento das normas às atividades da empresa.</p>
            </li>
          </ul>
        </div>
        <StaticImage
          src="../../images/solução/modulo_manual.svg"
          alt="modulo manual"
          layout="constrained"
          placeholder="tracedSVG"
          height="1600"
        />
      </TextList>
    </div>
  );
}
