import * as React from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import AccountingKnowledge from "../components/AccountingKnowledge";
import ManualModule from "../components/ManualModule";
import CheckList from "../components/CheckList";
import ThreadForum from "../components/ThreadForum";
import Planos from "../components/Plans";
import Ifrs16 from "../components/Ifrs16";
import AccountsPlans from "../components/AccountsPlans";
import Main from "../common/Main";

export default function Solucao() {
  return (
    <Main>
      <Navbar backgroundColor="#cecccb" display="none" />
      <AccountingKnowledge />
      <ManualModule />
      <CheckList />
      <ThreadForum />
      <Ifrs16 />
      <AccountsPlans />
      <Planos />
      <Footer />
    </Main>
  );
}
