import React from "react";
import Button from "../../common/Button";
import { StaticImage } from "gatsby-plugin-image";

export default function Plans() {
  return (
    <div className="plans">
      <h2>planos</h2>
      <div className="plans__image">
        <StaticImage
          src="../../images/solução/planos.png"
          alt="planos"
          placeholder="tracedSVG"
          width="800"
        />
      </div>
      <Button hidden={true}>saiba mais</Button>
    </div>
  );
}
