import React from "react";
import SectionContainer from "../../common/SectionContainer";
import { StaticImage } from "gatsby-plugin-image";

export default function AccountingKnowledge() {
  return (
    <div className="accounting-knowledge">
      <SectionContainer backgroundColor="#e2dfdd">
        <StaticImage
          src="../../images/solução/conhecimento_contabil.svg"
          alt="Gestão Contábil"
          placeholder="tracedSVG"
          className="gestao-contabil"
        />
        <div className="accounting-knowledge__text">
          <h2>
            Simplificando e<br />
            disseminando o
          </h2>
          <h2>
            conhecimento
            <br />
            contábil.
          </h2>
        </div>
      </SectionContainer>
    </div>
  );
}
